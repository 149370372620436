import { Layout } from "antd";
import React from "react";
import { FC } from "react";
import styled from "styled-components";
import {
  PARTNER_1,
  PARTNER_2,
  PARTNER_3,
  PARTNER_4,
  PARTNER_5,
  PARTNER_6,
  PARTNER_7,
  PARTNER_8,
  PARTNER_9,
  PARTNER_10,
  PARTNER_11,
  PARTNER_11_B,
} from "../../../../assets/assets";
import { GroupWrap } from "../../../../components/group-wrap/group-wrap";
import { Stack } from "../../../../components/stack/stack";
import { Text } from "../../../../components/text/Text";
import { useBreakpoints } from "../../../../logic/hooks/use-breakpoints";
import { breakpoint } from "../../../../styles/breakpoints";
import { colors } from "../../../../styles/colors";
import { hpSectionHeadingSize } from "../../../../styles/themes";
const { Content } = Layout;

const StackWrapper = styled(Stack)`
  .logo {
    height: 120px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const SectionPositioning = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: flex-start;
  max-width: 1230px;
`;

const Section = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  height: 100%;
  min-height: 530px;
  flex: 1;
  background-color: ${colors.white};
  padding: 0 50px;
  ${breakpoint("xl")`padding: 0;`}
  .logo {
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    ${breakpoint("sm")`height: 120px;`}
  }
  .logo2 {
    width: 180px;
    margin-top: 8px;
    margin-bottom: 8px;
    ${breakpoint("sm")`width: 220px;`}
  }
  .logo3 {
    width: 140px;
    margin-top: 8px;
    margin-bottom: 8px;
    ${breakpoint("sm")`width: 140px;`}
  }
  .logo4 {
    height: 120px;
    margin-top: 8px;
    margin-bottom: 8px;
    ${breakpoint("sm")`width: 100px;`}
  }
`;

const PartnerLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Companies: FC = ({}) => {
  const { minTablet } = useBreakpoints();
  return (
    <Section id="section4">
      <div style={{ margin: "auto" }}>
        {/* <Content className="main-layout" style={{ backgroundColor: colors.white }}> */}
        {/* <Position className="main-content"> */}
        <SectionPositioning>
          <Stack flow="row">
            <div style={{ justifyItems: "flex-end", display: "flex" }}>
              <Text size={minTablet ? 128 : 64} strong>
                شركاؤنا
              </Text>
            </div>
            <div>
              <GroupWrap gap={2} alignItems="center" justifyContent="center">
                <PartnerLogoWrapper>
                  <img className="logo" src={PARTNER_5} />
                </PartnerLogoWrapper>
                <PartnerLogoWrapper>
                  <img className="logo" src={PARTNER_4} />
                </PartnerLogoWrapper>
                <PartnerLogoWrapper>
                  <img className="logo2" src={PARTNER_6} />
                </PartnerLogoWrapper>
                <PartnerLogoWrapper>
                  <img className="logo" src={PARTNER_3} />
                </PartnerLogoWrapper>
              </GroupWrap>

              <GroupWrap gap={1} alignItems="center" justifyContent="center">
                {/* Removed on Firas request <PartnerLogoWrapper>
                  <img className="logo" src={PARTNER_1} />
                </PartnerLogoWrapper> */}
                <PartnerLogoWrapper>
                  <img className="logo3" src={PARTNER_8} />
                </PartnerLogoWrapper>
                <PartnerLogoWrapper>
                  <img className="logo2" src={PARTNER_7} />
                </PartnerLogoWrapper>
                <PartnerLogoWrapper>
                  <img className="logo" src={PARTNER_2} />
                </PartnerLogoWrapper>
                <PartnerLogoWrapper>
                  <img className="logo2" src={PARTNER_11_B} />
                </PartnerLogoWrapper>
                <PartnerLogoWrapper>
                  <img className="logo2" src={PARTNER_9} />
                </PartnerLogoWrapper>
              </GroupWrap>
            </div>
          </Stack>
        </SectionPositioning>
        {/* </Position> */}
        {/* // </Content> */}
      </div>
    </Section>
  );
};
