import React from "react";
import "./master.page.styles.less";
import { Layout } from "antd";
import { MasterFooter } from "./components/master-footer/master-footer";
import { MasterHeader } from "./components/master-header/master-header";
import { BreakpointsProvider } from "../../logic/hooks/use-breakpoints";
import { colors } from "../../styles/colors";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
// import Navbar from "./components/master-header-2/Header";
// import Header from "./components/master-header-2/Header";

const { Content } = Layout;

const GlobalStyle = createGlobalStyle<{ isRtl: boolean }>`
  body {
    direction: ${({ isRtl }) => (isRtl ? "rtl" : "ltr")};
  }
`;

interface MasterPageProps {
  inProgress?: boolean;
  topSection?: React.ReactNode;
  pageHeader?: React.ReactNode;
  withFooter?: boolean;
  contentStyle?: React.CSSProperties;
  backgroundColor?: string;
}

export const MasterPage: React.FC<MasterPageProps> = ({
  children,
  inProgress,
  topSection,
  pageHeader,
  withFooter = true,
  contentStyle,
  backgroundColor,
}) => {
  // const { isRtl } = useContext(LanguageContext);
  const isRtl = true;
  return (
    <>
      <BreakpointsProvider>
        <Layout>
          <GlobalStyle isRtl={isRtl} />
          {/* <Header /> */}
          <MasterHeader />
          {pageHeader}
          {topSection && (
            <Content style={{ backgroundColor: "white" }}>{topSection}</Content>
          )}
          <Content className="main-layout" style={{ backgroundColor }}>
            <div className="main-content" style={contentStyle}>
              {children}
            </div>
          </Content>
          <Content
            className="main-layout"
            style={{ backgroundColor: colors.primary }}
          >
            <div className="main-content" style={contentStyle}>
              {withFooter && <MasterFooter />}
            </div>
          </Content>
        </Layout>
      </BreakpointsProvider>
    </>
  );
};
