import React, { createContext, FC, useContext, useMemo } from "react";
import { useMatchMedia } from "./use-match-media";

const assertContext = (context: unknown, name: string) => {
  if (context === undefined)
    throw new Error(
      `Either you or the component you used uses the use${name} hook which needs to be placed inside a ${name}Provider. Please wrap your component inside <${name}Provider></${name}Provider>.`
    );
};

export enum Breakpoints {
  minTablet = "min-width: 768px",
  minDesktop = "min-width: 992px",
  minWideDesktop = "min-width: 1200px",
  min1024 = "min-width: 1024px",
  min400 = "min-width: 400px",
  min500 = "min-width: 500px",
}

type ContextValue = {
  minTablet: boolean;
  min1024: boolean;
  min400: boolean;
  min500: boolean;
  minDesktop: boolean;
  minWideDesktop: boolean;
};

// pass undefined as any, because we run assertContext at runtime
const BreakpointsContext = createContext<ContextValue>(undefined as any);

export function useBreakpoints() {
  const context = useContext(BreakpointsContext);
  assertContext(context, "Breakpoints");
  return context;
}

export const BreakpointsProvider: FC = ({ children }) => {
  const minTablet = useMatchMedia(`(${Breakpoints.minTablet})`);
  const minDesktop = useMatchMedia(`(${Breakpoints.minDesktop})`);
  const minWideDesktop = useMatchMedia(`(${Breakpoints.minWideDesktop})`);
  const min1024 = useMatchMedia(`(${Breakpoints.min1024})`);
  const min400 = useMatchMedia(`(${Breakpoints.min400})`);
  const min500 = useMatchMedia(`(${Breakpoints.min500})`);

  const value = useMemo(
    () => ({ minTablet, minDesktop, minWideDesktop, min1024, min400, min500 }),
    [minTablet, minDesktop, minWideDesktop, min1024, min400, min500]
  );

  return (
    <BreakpointsContext.Provider value={value as any}>
      {children}
    </BreakpointsContext.Provider>
  );
};
