import { css, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components';

type BreakpointType = 'sm' | 'md' | 'lg' | 'xl';

const breakpoints: Record<BreakpointType, string> = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

export const isBreakpoint = (breakpoint: BreakpointType): boolean =>
  typeof window !== 'undefined' && window.innerWidth > Number.parseInt(breakpoints[breakpoint], 10);

export const breakpoint = (type: BreakpointType) => (
  strings: TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
): FlattenSimpleInterpolation => {
  return css`
    @media (min-width: ${breakpoints[type]}) {
      ${css(strings, ...interpolations)}
    }
  `;
};
