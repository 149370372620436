import React from "react";
import { HomePage } from "./pages/home/home.page";
import "./App.less";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  return <HomePage />;
}

export default App;
