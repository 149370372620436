import React from "react";
import { FC } from "react";
import styled from "styled-components";
import { GroupWrap } from "../../../../components/group-wrap/group-wrap";
import { Text } from "../../../../components/text/Text";
import { useBreakpoints } from "../../../../logic/hooks/use-breakpoints";
import {
  hpSectionHeadingSize,
  hpSectionParagraphSize,
} from "../../../../styles/themes";
import { HomepageSection } from "../section/HomepageSection";

const sectionHeight = 40;

export const UnderConstruction: FC<{
  backgroundColor: string;
  textColor: "primary" | "blueGray" | "grey" | "grey5" | "white";
}> = ({ backgroundColor, textColor }) => {
  const { minTablet } = useBreakpoints();
  return (
    <HomepageSection backgroundColor={backgroundColor} height={sectionHeight}>
      <GroupWrap gap={1} alignItems="center" justifyContent="center">
        <div style={{ padding: "8px" }}>
          <Text color={textColor} size={minTablet ? 18 : 14} strong>
            هذه نسخة أولية والموقع قيد الإنشاء. / This is a primary version and
            the website is under construction
          </Text>
        </div>
      </GroupWrap>
    </HomepageSection>
  );
};
