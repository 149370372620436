import { FC, Children, ReactNode } from "react";
import * as React from "react";
import { isFragment } from "react-is";
import styled from "styled-components";

const WrapContainer = styled.div<{
  gap: number;
  alignItems?: "center" | "baseline" | "normal";
  justifyContent?: "normal" | "space-between" | "end" | "center";
}>`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ gap }) => `-${gap / 2}rem`};
  align-items: ${({ alignItems = "center" }) => alignItems};
  justify-content: ${({ justifyContent = "normal" }) => justifyContent};
`;

const WrapItem = styled.div<{ gap: number }>`
  margin: ${({ gap }) => `${gap / 2}rem`};
`;

// sadly the gap rule is currently not really supported for flex
// that's why we need this workaround
export const GroupWrap: FC<{
  gap: number;
  alignItems?: "center" | "baseline" | "normal";
  justifyContent?: "normal" | "space-between" | "end" | "center";
  classes?: string;
}> = ({ children, classes, ...props }) => {
  return (
    <WrapContainer {...props}>
      {Children.map(children, function recursive(child, index): ReactNode {
        if (isFragment(child)) {
          return Children.map(child.props.children, recursive);
        } else if (child) {
          return (
            <WrapItem
              key={index}
              className={`group-wrap-item ${classes}`}
              gap={props.gap}
            >
              {child}
            </WrapItem>
          );
        } else {
          return null;
        }
      })}
    </WrapContainer>
  );
};
