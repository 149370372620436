import React from "react";
import { FC } from "react";
import styled from "styled-components";
import { Text } from "../../../../components/text/Text";
import { useBreakpoints } from "../../../../logic/hooks/use-breakpoints";
import {
  hpSectionHeadingSize,
  hpSectionParagraphSize,
} from "../../../../styles/themes";
import { HomepageSection } from "../section/HomepageSection";

const sectionHeight = 720;

export const OurGoals: FC = () => {
  const { minTablet } = useBreakpoints();
  return (
    <HomepageSection
      id="section2"
      backgroundImg="/images/section5.jpg"
      height={sectionHeight}
    >
      <div
        style={{
          paddingTop: minTablet ? `${sectionHeight / 8}px` : "16px",
          maxWidth: "1000px",
        }}
      >
        <div>
          <Text color="white" size={minTablet ? 128 : 64} strong>
            أهدافنا
          </Text>
        </div>
        <div>
          <Text color="white" size={minTablet ? 24 : 18}>
            <p>
              - تمكين ودعم التطور المستمر لأنماط العمل غير التقليدية والجديدة في
              سوق العمل بالمملكة العربية السعودية من خلال التقنيات الحديثة
            </p>
            <p>
              - بلورة وتنمية ترتيبات العمل المرنة وإنشاء نماذج عمل مستدامة تشجع
              على زيادة الاستثمار في أنماط العمل غير التقليدية.
            </p>
            <p>
              - تعزيز الإلتزام والإمتثال بالمعايير واللوائح المنظمة لسوق العمل
              غير التقليدي والحد من الممارسات الإقتصادية غير الرسمية وتأثيراتها
              السلبية الإقتصادية والإجتماعية.
            </p>
          </Text>
        </div>
      </div>
    </HomepageSection>
  );
};
