import { useState, useEffect } from "react";

// pass a query like `(min-width: 768px)`
export function useMatchMedia(query: string) {
  const hasWindow = typeof window !== `undefined`;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [matches, setMatches] = useState(() => {});

  useEffect(() => {
    if (hasWindow) {
      setMatches((matchMedia(query) as any).matches);
      const mediaQueryList = window.matchMedia(query);
      const onChange = (event: MediaQueryListEvent) =>
        setMatches(event.matches as any);

      // note 1: add/removeListener probably aren't deprecated
      // see https://github.com/microsoft/TypeScript/issues/32210
      // note 2: safari currently doesn't support add/removeEventListener
      mediaQueryList.addListener(onChange);
      return () => mediaQueryList.removeListener(onChange);
    }
    return undefined;
  }, [query]);

  return matches;
}

export default useMatchMedia;
