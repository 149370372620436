import { Layout } from "antd";
import React from "react";
import { FC } from "react";
import styled from "styled-components";
import {
  PARTNER_1,
  PARTNER_2,
  PARTNER_3,
  PARTNER_4,
  PARTNER_5,
  PRODUCT_1,
  PRODUCT_2,
  PRODUCT_3,
} from "../../../../assets/assets";
import { GroupWrap } from "../../../../components/group-wrap/group-wrap";
import { Stack } from "../../../../components/stack/stack";
import { Text } from "../../../../components/text/Text";
import { useBreakpoints } from "../../../../logic/hooks/use-breakpoints";
import { breakpoint } from "../../../../styles/breakpoints";
import { colors } from "../../../../styles/colors";
import { hpSectionHeadingSize } from "../../../../styles/themes";
const { Content } = Layout;

const StackWrapper = styled(Stack)`
  .logo {
    height: 120px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const SectionPositioning = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: flex-start;
  max-width: 1230px;
`;

const Section = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  height: 100%;
  min-height: 530px;
  flex: 1;
  background-color: ${colors.gray3};
  padding: 0 50px;
  ${breakpoint("xl")`padding: 0;`}
  .logo {
    width: 200px;
    margin-top: 8px;
    margin-bottom: 32px;
    ${breakpoint("sm")`width: 390px;`}
  }
`;

const PartnerLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OurProducts: FC = ({}) => {
  const { minTablet } = useBreakpoints();
  return (
    <Section id="section3">
      <div style={{ margin: "auto" }}>
        {/* <Content className="main-layout" style={{ backgroundColor: colors.white }}> */}
        {/* <Position className="main-content"> */}
        <SectionPositioning>
          <Stack flow="row">
            <div style={{ justifyItems: "flex-end", display: "flex" }}>
              <Text size={minTablet ? 128 : 64} strong>
                منتجاتنا
              </Text>
            </div>
            <div>
              <GroupWrap gap={1} alignItems="center" justifyContent="center">
                <a href="https://teleworks.sa/">
                  <PartnerLogoWrapper>
                    <img className="logo" src={PRODUCT_3} />
                  </PartnerLogoWrapper>
                </a>
                <a href="https://freelance.sa/">
                  <PartnerLogoWrapper>
                    <img className="logo" src={PRODUCT_2} />
                  </PartnerLogoWrapper>
                </a>
                <a href="https://mrn.sa/">
                  <PartnerLogoWrapper>
                    <img className="logo" src={PRODUCT_1} />
                  </PartnerLogoWrapper>
                </a>
              </GroupWrap>
            </div>
          </Stack>
        </SectionPositioning>
        {/* </Position> */}
        {/* // </Content> */}
      </div>
    </Section>
  );
};
