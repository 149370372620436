import React from "react";
import { FC } from "react";
import styled from "styled-components";
import { Text } from "../../../../components/text/Text";
import { useBreakpoints } from "../../../../logic/hooks/use-breakpoints";
import {
  hpSectionHeadingSize,
  hpSectionParagraphSize,
} from "../../../../styles/themes";
import { HomepageSection } from "../section/HomepageSection";

const sectionHeight = 720;

export const DigitalEvolution: FC = () => {
  const { minTablet } = useBreakpoints();
  return (
    <HomepageSection
      id="section1"
      backgroundImg="/images/section1.jpg"
      height={sectionHeight}
    >
      <div style={{ paddingTop: `${sectionHeight / 4}px`, maxWidth: "700px" }}>
        <div>
          <Text color="white" size={hpSectionHeadingSize(minTablet)}>
            تطور رقمي غيّر
            <span
              style={{
                fontWeight: "bold",
                paddingRight: "8px",
                paddingLeft: "8px",
              }}
            >
              عمل المستقبل
            </span>
          </Text>
        </div>
        <div>
          <Text color="white" size={hpSectionParagraphSize(minTablet)}>
            نسعى دائماً لخلق فرصًا جديدة تعمل باستمرار على تحسين مستويات المعيشة
            على مستوى العالم
          </Text>
        </div>
      </div>
    </HomepageSection>
  );
};
