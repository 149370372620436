import * as React from "react";
import { colors } from "../../styles/colors";
import { MasterPage } from "../master-page/master.page";
import { Companies } from "./components/companies/Companies";
import { DigitalEvolution } from "./components/digital-evolution/DigitalEvolution";
import { FutureWork } from "./components/future-work/FutureWork";
import { OurGoals } from "./components/our-goals/FutureWork";
import { OurProducts } from "./components/our-products/OurProducts";
import { UnderConstruction } from "./components/under-construction/UnderConstruction";

export const HomePage: React.FC = () => (
  <MasterPage
    topSection={
      <>
        <UnderConstruction backgroundColor="white" textColor="primary" />
        <DigitalEvolution />
        {/* Removed on Firas request <FutureWork /> */}
        <OurGoals />
        <OurProducts />
        <Companies />
        {/* Removed on Firas request, is only at the top <UnderConstruction backgroundColor={colors.primary} textColor="white" /> */}
      </>
    }
  ></MasterPage>
);
