export const COLOR_CATSKILL_WHITE = "#f5f8fa";
export const COLOR_AMARANTH = "#f2295b";

export const PRIMARY_COLOR = COLOR_AMARANTH;

export const hpSectionHeadingSize = (minTablet: boolean) =>
  minTablet ? 64 : 48;

export const hpSectionParagraphSize = (minTablet: boolean) =>
  minTablet ? 32 : 24;
