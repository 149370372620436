export const colors = {
  primary: "#1a223e",
  white: "#FFFFFF",
  black: "#000000",
  primaryDark: "#2a002D",
  blueGray: "#2F4251",
  darkGray: "#595858",
  grayText: "#929292",
  gray3: "#F3F2F3",
  gray4: "#DDDDDD",
  gray5: "#666666",
  gray6: "#F5F5F5",
  gray7: "#707070",
  gray8: "#EEEEEE",
  border: "#E4E4E4",
  lightTeal: "#F5F8FA",
  green: "#016E00",
  darkGreen: "#015D00",
};

export const gradient = {
  primary: `linear-gradient(30deg, ${colors.primaryDark} 9%, ${colors.primary} 76%)`,
};
