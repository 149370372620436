import { GroupWrap } from "../../../../components/group-wrap/group-wrap";
import React from "react";
import styled from "styled-components";
// import tw from "twin.macro";
import { Text } from "../../../../components/text/Text";
import { Stack } from "../../../../components/stack/stack";
// import { Link } from "react-router-dom";
// import { AppRoute } from "../../../../app/app.route.const";
import { colors } from "../../../../styles/colors";
import { useBreakpoints } from "../../../../logic/hooks/use-breakpoints";
import { LOGO, LOGO_TAKAMOL } from "../../../../assets/assets";

const Container = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  .logo {
    width: 120px;
    margin: 8px;
  }
  color: white;
`;

const FooterStripContainer = styled(GroupWrap)`
  .footer-strip-container {
    &:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }
`;

// const FooterPulledRight = styled.div`
//   display: flex;
//   flex: 1;
//   align-items: center;
//   justify-content: flex-end;
//   color: ${colors.primary};
//   margin-right: 1rem;
// `;

const Company = styled.div`
  font-family: "Permanent Marker", "Open Sans", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 22px;
  margin-right: 8px;
`;

// const FooterLink = styled(Link)``;

// const FooterLinkWrapper = styled.div`
//   ${({ min400 }: { min400: boolean }) =>
//     min400
//       ? ''
//       : css`
//           min-width: 160px;
//         `}
// `;

export const FooterStrip: React.FC = () => {
  return (
    <Container>
      <FooterStripContainer gap={0.5} classes="footer-strip-container">
        <Stack gap={0.5} flow="row" justifyContent="start" alignItems="center">
          <Company>
            {/* Removed on Firas request <img className="logo" src={LOGO_TAKAMOL} alt="logo takamol" /> */}
            <img className="logo" src={LOGO} alt="logo" />
          </Company>
          <Text
            color="white"
            size={14}
            style={{ direction: "ltr" }}
          >{`2020 لشركة عمل المستقبل © جميع الحقوق محفوظة`}</Text>
        </Stack>
        {/* <FooterPulledRight>
          <GroupWrap gap={1}>
            <FooterLinkWrapper min400={min400}>
              <FooterLink to={AppRoute.FooterImpressum}>
                <Text size={14} strong>
                  {translate(translationKeys.footer.links.imprint)}
                </Text>
              </FooterLink>
            </FooterLinkWrapper>
            <FooterLinkWrapper min400={min400}>
              <FooterLink to={AppRoute.FooterTOS}>
                <Text size={14} strong>
                  {translate(translationKeys.footer.links.termsOfUse)}
                </Text>
              </FooterLink>
            </FooterLinkWrapper>
            <FooterLinkWrapper min400={min400}>
              <FooterLink to={AppRoute.FooterDataProtection}>
                <Text size={14} strong>
                  {translate(translationKeys.footer.links.dataProtection)}
                </Text>
              </FooterLink>
            </FooterLinkWrapper>
          </GroupWrap>
        </FooterPulledRight> */}
      </FooterStripContainer>
    </Container>
  );
};
