import React, { RefObject } from "react";
import "./master-header.styles.less";
import { Layout } from "antd";
import {
  useIsDesktop,
  useIsWideDesktop,
} from "../../../../logic/hooks/use-is-tablet";
import { LOGO, LOGO_PRIMARY } from "../../../../assets/assets";
import styled from "styled-components";
import { Burger, Menu } from "../Burger";
import { GroupWrap } from "../../../../components/group-wrap/group-wrap";
import { colors } from "../../../../styles/colors";
import { Text } from "../../../../components/text/Text";
const { Header } = Layout;

export const MasterHeader: React.FC = () => {
  const isDesktop = useIsDesktop();
  const isWideDesktop = useIsWideDesktop();
  const [open, setOpen] = React.useState(false);
  const node = React.useRef() as RefObject<HTMLDivElement>;

  return (
    // <Header className="header">
    //   <img className="logo" src={LOGO} height={80} alt="logo" />
    //   <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["2"]}>
    //     <Menu.Item key="1">nav 1</Menu.Item>
    //     <Menu.Item key="2">nav 2</Menu.Item>
    //     <Menu.Item key="3">nav 3</Menu.Item>
    //   </Menu>
    // </Header>
    <Header className="master-header noselect">
      <div className="header-container">
        <div className="left-menu-content">
          <img className="logo" src={LOGO_PRIMARY} alt="logo" />

          {!isDesktop && (
            <div ref={node}>
              <Burger open={open} setOpen={setOpen} />
              {open && <Menu setOpen={setOpen} open={open} />}
            </div>
          )}
          {isDesktop && (
            <div
              style={{
                color: colors.primary,
                marginRight: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <GroupWrap gap={2}>
                <a
                  href="/#section1"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Text color="primary" size={18}>
                    عمل المستقبل
                  </Text>
                </a>
                <a
                  href="/#section2"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Text color="primary" size={18}>
                    أهدافنا
                  </Text>
                </a>
                <a
                  href="/#section3"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Text color="primary" size={18}>
                    منتجاتنا
                  </Text>
                </a>
                <a
                  href="/#section4"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Text color="primary" size={18}>
                    شركاؤنا
                  </Text>
                </a>
              </GroupWrap>
            </div>
          )}
          {/* {isWideDesktop && <div className="header-title">Some Text</div>}
          {!isDesktop && (
            <div className="right-menu-content">{/* <RightMenu /> *}</div>
          )} */}
        </div>
        {/* {isDesktop && (
          <div className="right-menu-content">
            <RightMenu />
          </div>
        )} */}
      </div>
    </Header>
  );
};
