import React from "react";
import { FooterStrip } from "./footer-strip";
import { Layout } from "antd";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";

const { Footer } = Layout;

const FooterWithPadding = styled(Footer)`
  background-color: ${colors.primary};
  padding: 16px 8px;
`;

export const MasterFooter: React.FC = () => {
  return (
    <FooterWithPadding>
      <FooterStrip />
    </FooterWithPadding>
  );
};
