import React from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";

const DefaultTextStyles = styled(Typography.Text)`
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: anywhere;
  ${({ reverse }: { reverse: boolean }) =>
    reverse
      ? css`
          -webkit-transform: rotateY(180deg);
          -moz-transform: rotateY(180deg);
          -o-transform: rotateY(180deg);
          -ms-transform: rotateY(180deg);
        `
      : css``}
`;

export const Text: React.FC<
  TextProps & {
    color?: "primary" | "blueGray" | "grey" | "grey5" | "white";
    size?: number;
    textAlign?: "justify" | "left";
    noWrap?: boolean;
    reverse?: boolean;
  }
> = ({
  children,
  color,
  size,
  textAlign,
  noWrap,
  reverse = false,
  style,
  ...rest
}) => (
  <DefaultTextStyles
    reverse={reverse}
    {...rest}
    style={{
      color: !color
        ? undefined
        : color === "primary"
        ? colors.primary
        : color === "grey"
        ? colors.gray3
        : color === "grey5"
        ? colors.gray3
        : color === "blueGray"
        ? colors.blueGray
        : color === "white"
        ? colors.white
        : undefined,
      fontSize: size ? `${size}px` : undefined,
      textAlign,
      whiteSpace: noWrap ? "nowrap" : "normal",
      ...style,
    }}
  >
    {children}
  </DefaultTextStyles>
);
