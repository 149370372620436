import React from "react";
import { FC } from "react";
import styled, { css } from "styled-components";
import { breakpoint } from "../../../../styles/breakpoints";

const Section = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 50px;
  ${breakpoint("xl")`padding: 0;`}
  ${({
    height,
    backgroundImg,
    backgroundColor,
  }: {
    height?: number;
    backgroundImg?: string;
    backgroundColor?: string;
  }) => css`
    ${backgroundImg
      ? css`
          background: url("${backgroundImg}") no-repeat scroll 15% 15% / cover
            rgba(0, 0, 0, 0);
        `
      : ""}
    ${backgroundColor
      ? css`
          background-color: ${backgroundColor};
        `
      : "background-color: white"}
  `}
`;

export const HomepageSection: FC<{
  height?: number;
  backgroundImg?: string;
  id?: string;
  backgroundColor?: string;
}> = ({ height, id, backgroundImg, backgroundColor, children }) => {
  return (
    <Section
      id={id}
      backgroundImg={backgroundImg}
      backgroundColor={backgroundColor}
    >
      <div
        style={{
          minHeight: height ? `${height}px` : "720px",
          height: "100%",
        }}
      >
        <div style={{ maxWidth: "1230px", margin: "auto" }}>{children}</div>
      </div>
    </Section>
  );
};
